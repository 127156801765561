import {fade} from '@material-ui/core/styles';

export const useStyles = theme => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        color: "#fff"
    },
    appBar: {
        position: 'relative'
    },
    layout: {
        width: 'auto',
        margin: theme.spacing(2),
        [
            theme
                .breakpoints
                .up(600 + theme.spacing(2) * 2)
        ]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    layoutT: {
        width: 'auto',
        margin: theme.spacing(2),
        [
            theme
                .breakpoints
                .up(600 + theme.spacing(2) * 2)
        ]: {
            width: 600,
            marginLeft: '3',
            marginRight: 'auto'
        }
    },
    paperAppBar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        backgroundColor: '#ffffff',
        borderRadius:10
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: '#ffffff',
        borderRadius:10
    },
    chip: {
        marginTop: 10,
        marginRight: 10
    },
    chipTable: {
        margin: 0,
    },
    paperTitle: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        color: '#2e2e2e'
    },
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        // vertical padding + font size from searchIcon
        paddingBottom: 7,
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme
            .transitions
            .create('width'),
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    }
});
