import React, {Component} from 'react';
import './App.css';
import CoinTable from './table';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6kn7567xHQyAfR6ZvAr2phB11CVvm5ZI",
  authDomain: "kpre-kr.firebaseapp.com",
  projectId: "kpre-kr",
  storageBucket: "kpre-kr.appspot.com",
  messagingSenderId: "283053789956",
  appId: "1:283053789956:web:18ea77f543354cd922f651",
  measurementId: "G-E5MPYVDZ65"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends Component {
    render() {
        return (
            <CoinTable/>
        );
    }
}

export default App;