import React, {useState, useEffect} from "react";
import Chip from '@material-ui/core/Chip';

export function Timerr() {
    const [minutes, setMinutes] = useState(10);
    const [seconds, setSeconds] = useState(0);
    const [total, setTotal] = useState("10:00");

    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                if (parseInt(minutes) === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(parseInt(minutes) - 1);
                    setSeconds(59);
                }
            }

            let tmin = seconds
            if (tmin < 10) {
                tmin = "0" + tmin
            }
            setTotal(minutes + ":" + tmin);

            if(seconds == 0 && minutes == 0){
                window.location.href=window.location.href;
            }

        }, 1000);
        return() => clearInterval(countdown);
    }, [minutes, seconds]);

    return (
        <div className="App">
            {/* <Chip label={total} color="#000000" size="small"/>
            &nbsp;
            <Chip label="&nbsp;제휴문의 : notepad9798@gmail.com&nbsp;" color="#000000" size="small"/> */}
        </div>
    );
}

export default Timerr;